// getHashedInvitationID returns just the hashed ID from the paths:
// http://gatherings.warmspace.io/HASHED_ID
// http://app.warmspace.io/s/HASHED_ID
export const getHashedInvitationID = () => {
  const parts = window.location.pathname.split("/");

  const hashedInvitationID: string | null = parts[parts.length - 1];

  // Check if we're using the new /s/ format
  if (parts.length == 3 && parts[1] == "s") {
    return hashedInvitationID;
  }

  // TODO: the following shouldn't be necessary once we've fully migrated to the above URL structure
  // Make sure the hashed ID is a valid format.
  // Four or five characters long, alphanumeric.
  // Not a path to the dashboard.
  if (
    !/^[a-zA-Z0-9]{4,5}$/.test(hashedInvitationID) ||
    hashedInvitationID === "team" ||
    hashedInvitationID === "teams" ||
    hashedInvitationID === "join" ||
    hashedInvitationID === "email" ||
    hashedInvitationID === "login" ||
    hashedInvitationID === "flows"
  ) {
    return null;
  }

  return hashedInvitationID;
};
